import React, { useState, useEffect } from 'react';
import {
  Route, Switch, BrowserRouter,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { darkTheme } from 'Themes';
import Preloader from 'Components/Preloader/Preloader';
import ErrorBoundary from './ErrorBoundary';
import Routes from './routes.config';

import 'jquery/src/jquery';
import 'popper.js/dist/popper';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import './i18n';
import './styles/main.css';

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <ErrorBoundary>
          {
            isLoading
              ? <Preloader />
              : (
                <Switch>
                  {
                    Routes.map((route) => <Route key={route.id} {...route} />)
                  }
                </Switch>
              )
          }
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
