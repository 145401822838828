export default () => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  cursorNotAllowed: {
    cursor: 'not-allowed',
  },
});
