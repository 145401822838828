import React from 'react';
import { Field } from 'formik';
import { Box, TextField } from '@material-ui/core';
import { formConstants } from 'Constants';
import InputField from 'Components/InputField/InputField.component';

const { MESSAGE_MAX_LENGTH } = formConstants.contactForm;

const ContactForm = () => {
  const getRemainingCharactersMessage = (message) => (
    message.length
      ? `You have ${MESSAGE_MAX_LENGTH - message.length} characters remaining`
      : ''
  );

  return (
    <form noValidate autoComplete="off">
      <Box display="flex" mb={1}>
        <Box flexGrow={1} pr={1}>
          <InputField
            label="First Name"
            id="first-name"
            margin="dense"
            fullWidth
            variant="outlined"
            name="firstName"
          />
        </Box>
        <Box flexGrow={1} pl={1}>
          <InputField
            label="Last Name"
            id="last-name"
            margin="dense"
            variant="outlined"
            fullWidth
            name="lastName"
          />
        </Box>
      </Box>
      <Box mb={1}>
        <InputField
          label="Email"
          id="email"
          margin="dense"
          fullWidth
          variant="outlined"
          name="email"
        />
      </Box>
      <Box mb={2}>
        <Field name="message">
          {({ field, meta }) => (
            <TextField
              id="message"
              label="How can I help you?"
              multiline
              rows={4}
              margin="dense"
              fullWidth
              variant="outlined"
              {...field}
              error={Boolean(meta.touched && meta.error)}
              inputProps={{
                maxLength: MESSAGE_MAX_LENGTH,
              }}
              helperText={
                (meta.touched && meta.error)
                || getRemainingCharactersMessage(field.value)
              }
            />
          )}
        </Field>
      </Box>
    </form>
  );
};

export default ContactForm;
