import React from 'react';

import SidebarLayout from 'Layouts/SidebarLayout/SidebarLayout';
import Portfolio from 'Containers/Portfolio/Portfolio';

const PortfolioPage = () => (
  <SidebarLayout>
    <Portfolio />
  </SidebarLayout>
);

export default PortfolioPage;
