import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Slide, Box } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import PropTypes from 'prop-types';
import styles from './styles';

const PRELOADER_TYPE = {
  BLACK: 'black',
  YELLOW: 'yellow',
};

const Page = ({
  type,
  index,
  inProp,
  message,
  classes,
}) => (
  <Box
    bgcolor={type === PRELOADER_TYPE.BLACK ? grey[900] : 'primary.main'}
    className={classes.container}
  >
    <Slide
      timeout={{ appear: 300, enter: 700, exit: 0 }}
      style={{ zIndex: index }}
      direction={type === PRELOADER_TYPE.BLACK ? 'up' : 'right'}
      in={inProp}
      mountOnEnter
      unmountOnExit
    >
      <Box
        className="full-screen"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={type === PRELOADER_TYPE.BLACK ? grey[900] : 'primary.main'}
        color={type !== PRELOADER_TYPE.BLACK ? grey[900] : 'primary.main'}
      >
        <Box
          component="span"
          className="pf"
          fontSize="h3.fontSize"
        >
          {message}
        </Box>
      </Box>
    </Slide>
  </Box>
);

Page.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.oneOf([PRELOADER_TYPE.BLACK, PRELOADER_TYPE.YELLOW]).isRequired,
  index: PropTypes.number.isRequired,
  inProp: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default withStyles(styles)(Page);

export { PRELOADER_TYPE };
