import React from 'react';
import CenteredLayout from 'Layouts/CenteredLayout/CenteredLayout';
import FullPageError from 'Components/Errors/FullPageError/FullPageError.component';
import errorPagesData from 'STATIC_DATA/errorPagesData';

const PageNotFound = () => (
  <CenteredLayout>
    <FullPageError
      {...errorPagesData.notFound}
    />
  </CenteredLayout>
);

export default PageNotFound;
