export default (theme) => {
  const rem = theme.typography.pxToRem;
  return {
    container: {
      minHeight: '100vh',
      scrollSnapAlign: 'start',
    },
    descripiton: {
      fontSize: rem(16),
      color: theme.palette.text.disabled,
    },
    textDecorationNone: {
      textDecoration: 'none !important',
    },
    projectLink: {
      textDecorationColor: `${theme.palette.text.secondary} !important`,
    },
  };
};
