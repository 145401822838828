export default (theme) => {
  const rem = theme.typography.pxToRem;

  return {
    section: {
      border: `1px solid ${theme.palette.grey[800]}`,
      padding: rem(16),
      borderLeft: 'none',
      '&:first-child': {
        borderLeft: `1px solid ${theme.palette.grey[800]}`,
        [theme.breakpoints.down('xs')]: {
          borderTop: `1px solid ${theme.palette.grey[800]}`,
        },
      },
      [theme.breakpoints.down('xs')]: {
        borderTop: 'none',
        borderLeft: `1px solid ${theme.palette.grey[800]}`,
      },
    },
    contentSection: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    mainArea: {
      minHeight: rem(400),
      backgroundColor: theme.palette.primary.main,
    },
    link: {
      color: `${theme.palette.primary.main} !important`,
      '& a:hover, & a:active': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    introduction: {
      flexGrow: 0,
    },
    descriptionWrapper: {
      flexGrow: 1,
      overflowX: 'hidden',
    },
    description: {
      width: '100%',
      margin: rem(16),
      maxHeight: rem(235),
      overflowY: 'scroll',
      [theme.breakpoints.down('xs')]: {
        maxHeight: 'none',
        overflowY: 'auto',
        width: 'auto',
      },
    },
    links: {
      flexGrow: 0,
    },
    banner: {
      padding: rem(32),
      '& img': {
        maxWidth: '100%',
        maxHeight: 'auto',
        borderRadius: rem(4),
        boxShadow: theme.shadows[10],
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
          height: rem(450),
        },
      },
    },
    thumbnail: {
      border: 'none',
      maxWidth: rem(100),
      position: 'relative',
      '& img': {
        maxWidth: '100%',
        maxHeight: 'auto',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: rem(50),
      },
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };
};
