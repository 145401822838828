import React, { useState, useEffect } from 'react';
import Page, { PRELOADER_TYPE } from 'Components/Preloader/Page';

function Preloader() {
  const messageArray = ['Let\'s', 'Develop', 'Together'];

  const [state, setstate] = useState({ on: true, index: 0, message: messageArray[0] });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setstate({
        on: !state.on,
        index: state.index + 1,
        message: messageArray[(state.index + 1) % 3],
      });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [state]);

  if (state.on) {
    return (
      <Page
        type={PRELOADER_TYPE.BLACK}
        inProp={state.on}
        index={state.index}
        message={state.message}
      />
    );
  }
  return (
    <Page
      type={PRELOADER_TYPE.YELLOW}
      inProp={!state.on}
      index={state.index}
      message={state.message}
    />
  );
}

export default Preloader;
