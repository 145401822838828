import defaultValues from './defaultValues';
import validationSchema from './validationSchema';
import Form from './ContactForm.component';

export default Form;

export {
  validationSchema,
  defaultValues,
};
