export default (theme) => {
  const rem = theme.typography.pxToRem;
  return {
    container: {
      minHeight: '100vh',
    },
    content: {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    title: {
      fontSize: rem(48),
      padding: `0 ${rem(12)} 0 0`,
      borderRight: `1px solid ${theme.palette.primary.main}`,
      [theme.breakpoints.down('xs')]: {
        fontSize: rem(32),
        padding: `0 0 ${rem(12)}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderRight: 'none',
        marginBottom: rem(16),
      },
    },
  };
};
