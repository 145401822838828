import axios from 'axios';

const axiosInstance = axios.create();

const request = (options) => {
  const onSuccess = (response) => response;

  const onError = (error) => Promise.reject(error.response || error.message);

  return axiosInstance(options)
    .then(onSuccess)
    .catch(onError);
};

export default request;
