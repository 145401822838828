import React from 'react';
import CenteredLayout from 'Layouts/CenteredLayout/CenteredLayout';
import ProjectContainer from 'Containers/ProjectContainer/ProjectContainer';

const ProjectPage = () => (
  <CenteredLayout>
    <ProjectContainer />
  </CenteredLayout>
);

export default ProjectPage;
