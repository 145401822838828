export default (theme) => {
  const rem = theme.typography.pxToRem;
  return {
    container: {
      minHeight: '100vh',
    },
    title: {
      fontSize: rem(64),
      [theme.breakpoints.down('xs')]: {
        fontSize: rem(32),
      },
    },
    textDecorationNone: {
      textDecoration: 'none !important',
    },
  };
};
