import React from 'react';
import { Redirect } from 'react-router-dom';

import { routeConstants } from 'Constants';

import LandingPage from 'Pages/LandingPage';
import PortfolioPage from 'Pages/PortfolioPage';
import ProjectPage from 'Pages/ProjectPage';
import ContactPage from 'Pages/ContactPage';
import AboutPage from 'Pages/AboutPage';
import PageNotFound from 'Pages/PageNotFound';
import BlogsPage from 'Pages/BlogsPage';

export default [
  {
    id: 1,
    path: routeConstants.HOME,
    exact: true,
    component: LandingPage,
  },
  {
    id: 2,
    path: routeConstants.ABOUT,
    exact: true,
    component: AboutPage,
  },
  {
    id: 3,
    path: routeConstants.PORTFOLIO,
    exact: true,
    component: PortfolioPage,
  },
  {
    id: 4,
    path: routeConstants.PROJECT,
    exact: true,
    component: ProjectPage,
  },
  {
    id: 5,
    path: routeConstants.BLOGS,
    exact: true,
    component: BlogsPage,
  },
  {
    id: 6,
    path: routeConstants.CONTACT,
    exact: true,
    component: ContactPage,
  },
  {
    id: 7,
    path: routeConstants.PAGE_NOT_FOUND,
    exact: true,
    component: PageNotFound,
  },
  {
    id: 8,
    path: '*',
    exact: true,
    component: () => <Redirect to={routeConstants.PAGE_NOT_FOUND} />,
  },
];
