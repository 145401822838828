export default {
  inquiryScuttle: {
    BASE_API_URL: 'https://inquiry-scuttle.herokuapp.com',
    CREATE_INQUIRY: '/clientapi/create/inquiry',
  },
  methods: {
    POST: 'post',
  },
  apiStatus: {
    SUCCESS: 200,
  },
};
