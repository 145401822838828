export default (theme) => {
  const rem = theme.typography.pxToRem;

  return {
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: theme.zIndex.drawer - 1,
      color: '#fff',
      width: '100%',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
    },
    toggleButton: {
      zIndex: theme.zIndex.drawer,
      position: 'fixed',
      color: theme.palette.primary.main,
      top: rem(16),
      right: rem(16),
      outline: 'none !important',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    mainContent: {
      backgroundColor: theme.palette.grey[900],
      minHeight: '100vh',
      width: '100%',
    },
    container: {
      minHeight: '100vh',
      padding: `0 ${rem(16)}`,
      [theme.breakpoints.up('sm')]: {
        padding: `0 ${rem(75)}`,
      },
    },
    mobileOnly: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    desktopOnly: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    scrollSnapContainer: {
      scrollSnapType: 'y proximity',
      overflowY: 'scroll',
      height: '100vh',
    },
  };
};
