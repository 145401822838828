import React from 'react';
import PropTypes from 'prop-types';

const ResponsiveImage = ({ responsive, defaultOption }) => (
  <picture>
    {
      responsive.map(({ breakpoint, src, id }) => (
        <source
          key={id}
          media={breakpoint}
          srcSet={src}
        />
      ))
    }
    <img
      src={defaultOption.src}
      alt={defaultOption.alt}
      aria-label={defaultOption.ariaLabel}
      className={defaultOption.cssClasses?.join(' ')}
    />
  </picture>
);

ResponsiveImage.propTypes = {
  responsive: PropTypes.arrayOf(PropTypes.shape({
    breakpoint: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  })),
  defaultOption: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    ariaLabel: PropTypes.string,
    cssClasses: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

ResponsiveImage.defaultProps = {
  responsive: [],
};

export default ResponsiveImage;
