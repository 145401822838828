const projectData = [
  {
    id: 0,
    name: 'SalesIcon',
    website: 'https://salesicon-crm.web.app',
    repositories: [
      { label: 'Frontend', link: 'https://github.com/sharvan-sharma/SalesIcon-CRM-frontend' },
      { label: 'Backend', link: 'https://github.com/sharvan-sharma/SalesIcon-CRM-backend-API' },
    ],
    tags: ['React', 'Redux', 'Node.js', 'Express', 'MongoDB', 'Chart.js', 'Amazon S3'],
    description: 'SalesIcon CRM is a Minimum Viable Product of lead management solution that keeps all your leads in one centralized, customizable, and easy-to-use-platform.',
    shortDescription: 'Lead Management System',
    images: {
      lg: [
        { link: 'https://salesicon-crm.web.app', alt: 'landing', src: '/project_images/salesicon/landing.jpg' },
        { link: 'https://salesicon-crm.web.app/login/admin', alt: 'login', src: '/project_images/salesicon/login.jpg' },
        { link: 'https://salesicon-crm.web.app', alt: 'dashboard', src: '/project_images/salesicon/dashboard.jpg' },
      ],
      sm: [
        { link: 'https://salesicon-crm.web.app', alt: 'landing', src: '/project_images/salesicon/landing_mbl.jpg' },
        { link: 'https://salesicon-crm.web.app/login/admin', alt: 'login', src: '/project_images/salesicon/login_mbl.jpg' },
        { link: 'https://salesicon-crm.web.app', alt: 'dashboard', src: '/project_images/salesicon/dashboard_mbl.jpg' },
      ],
    },
  },
  {
    id: 1,
    name: 'Inquiry Scuttle',
    website: 'https://inquiryscuttle.web.app',
    repositories: [
      { label: 'Frontend', link: 'https://github.com/sharvan-sharma/Inquiry-Scuttle-Frontend-Application' },
      { label: 'Backend', link: 'https://github.com/sharvan-sharma/Inquiry-Scuttle-backend-API' },
    ],
    tags: ['React', 'Redux', 'Node.js', 'Express', 'MongoDB'],
    description: 'Inquiry Scuttle is an Inquiry Management Service that manages all the contact and inquiry pages of your applications.',
    shortDescription: 'Inquiry Management Service',
    images: {
      lg: [
        { link: 'https://inquiryscuttle.web.app', alt: 'landing', src: '/project_images/ins/landing.jpg' },
        { link: 'https://inquiryscuttle.web.app/login/signup', alt: 'signup', src: '/project_images/ins/signup.jpg' },
        { link: 'https://inquiryscuttle.web.app', alt: 'dashboard', src: '/project_images/ins/dashboard.jpg' },
      ],
      sm: [
        { link: 'https://inquiryscuttle.web.app', alt: 'landing', src: '/project_images/ins/landing-mbl.jpg' },
        { link: 'https://inquiryscuttle.web.app/signup', alt: 'signup', src: '/project_images/ins/signup-mbl.jpg' },
        { link: 'https://inquiryscuttle.web.app', alt: 'dashboard', src: '/project_images/ins/dashboard-mbl.jpg' },
      ],
    },
  },
  {
    id: 2,
    name: 'CryPt',
    website: 'https://crypt-oauth.web.app',
    repositories: [
      { label: 'Frontend', link: 'https://github.com/sharvan-sharma/CryPt-client' },
      { label: 'Backend', link: 'https://github.com/sharvan-sharma/CryPt-server' },
      { label: 'Documentation', link: 'https://crypt-docs.netlify.com' },
      { label: 'Passport-crypt-oauth20 (npm package)', link: 'https://www.npmjs.com/package/passport-crypt-oauth20' },
    ],
    tags: ['React', 'Redux', 'Node.js', 'Express', 'MongoDB', 'Oauth 2.0', 'Gatsby'],
    description: 'CryPt is an OAuth 2.0 service inspired by Google OAuth 2.0 that provide authentication to client applications.',
    shortDescription: 'OAuth 2.0 Service',
    images: {
      lg: [
        { link: 'https://crypt-oauth.web.app', alt: 'landing', src: '/project_images/crypt/landing.jpg' },
        { link: 'https://crypt-oauth.web.app', alt: 'login', src: '/project_images/crypt/login.jpg' },
        { link: 'https://crypt-oauth.web.app', alt: 'signup', src: '/project_images/crypt/signup.jpg' },
      ],
      sm: [
        { link: 'https://crypt-oauth.web.app', alt: 'landing', src: '/project_images/crypt/landing_mbl.jpg' },
        { link: 'https://crypt-oauth.web.app', alt: 'login', src: '/project_images/crypt/login_mbl.jpg' },
        { link: 'https://crypt-oauth.web.app', alt: 'signup', src: '/project_images/crypt/signup_mbl.jpg' },
      ],
    },
  },
  {
    id: 3,
    name: 'NotesKeeper.md',
    website: 'https://noteskeeper-md.web.app',
    repositories: [
      { label: 'Frontend', link: 'https://github.com/robinkataria/NotesKeeper.md-FRONTEND' },
      { label: 'Backend', link: 'https://github.com/robinkataria/NotesKeeper.md-BACKEND' },
    ],
    tags: ['React', 'Redux', 'Node.js', 'MongoDb'],
    shortDescription: 'Note Taking App',
    description: 'NotesKeeper.md is a note-taking application with a built-in markdown editor and todo-lists support.',
    images: {
      lg: [
        { link: 'https://noteskeeper-md.web.app', alt: 'landing-page', src: '/project_images/noteskeeper/landing-page.png' },
        { link: 'https://noteskeeper-md.web.app/login', alt: 'dashboard', src: '/project_images/noteskeeper/dashboard.png' },
        { link: 'https://noteskeeper-md.web.app', alt: 'contact-page', src: '/project_images/noteskeeper/contact-page.png' },
      ],
      sm: [
        { link: 'https://noteskeeper-md.web.app', alt: 'landing', src: '/project_images/noteskeeper/landing-page-mbl.png' },
        { link: 'https://noteskeeper-md.web.app/login', alt: 'login', src: '/project_images/noteskeeper/dashboard-mbl.png' },
        { link: 'https://noteskeeper-md.web.app', alt: 'editor', src: '/project_images/noteskeeper/contact-page-mbl.png' },
      ],
    },
  },
];

export default projectData;
