export default (theme) => {
  const rem = theme.typography.pxToRem;
  return {
    aligner: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: `0 ${rem(16)}`,
    },
    heading: {
      fontSize: rem(24),
      color: theme.palette.text.secondary,
    },
    description: {
      fontSize: rem(16),
      color: theme.palette.text.disabled,
    },
    highlightedText: {
      color: theme.palette.text.secondary,
    },
    spacer: {
      paddingBottom: rem(72),
    },
    button: {
      margin: `0 ${rem(16)} ${rem(16)} 0`,
    },
    textDecorationNone: {
      textDecoration: 'none !important',
    },
  };
};
