import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const CenteredLayout = ({ children, classes }) => (
  <div className={classes.page}>
    <Container maxWidth="lg" classes={{ root: classes.container }}>
      {children}
    </Container>
  </div>
);

CenteredLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(CenteredLayout);
