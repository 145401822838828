import React from 'react';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

export default {
  notFound: {
    title: '404',
    headings: [
      'PAGE NOT FOUND',
    ],
    paragraphs: [
      'The page you are looking for is not available.',
    ],
    buttons: [
      {
        text: 'Back to Home',
        startIcon: <DoubleArrowIcon />,
        variant: 'outlined',
        color: 'primary',
        action: () => {
          window.location.href = '/';
        },
      },
    ],
  },
  errorBoundary: {
    title: 'Oops!',
    headings: [
      'UNEXPECTED ERROR',
    ],
    paragraphs: [
      'It seems something went wrong.',
    ],
    buttons: [
      {
        text: 'Back to Home',
        startIcon: <DoubleArrowIcon />,
        variant: 'outlined',
        color: 'primary',
        action: () => {
          window.location.href = '/';
        },
      },
    ],
  },
};
