import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Button, Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { routeConstants } from 'Constants';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import styles from './styles';

const ConstructionPart = ({ classes }) => (
  <Box
    display="flex"
    className={classes.container}
    alignItems="center"
    justifyContent="center"
  >
    <Box
      textAlign="center"
      my={3}
      className={classes.content}
    >
      <Fade in timeout={1000}>
        <Box color="text.secondary" component="p">
          I&apos;m still
        </Box>
      </Fade>
      <Fade in timeout={1500}>
        <Box
          color="primary.main"
          fontWeight="fontWeightLight"
          letterSpacing={10}
          className={classes.title}
          mb={2}
        >
          COOKING BLOGS
        </Box>
      </Fade>
      <Fade in timeout={2000}>
        <Box color="text.secondary" component="p" mb={1}>
          I&apos;m going to publish blogs very soon.
        </Box>
      </Fade>
      <Fade in timeout={2500}>
        <Box color="text.secondary" component="p" mb={3}>
          Stay Tune.
        </Box>
      </Fade>
      <Fade in timeout={3000}>
        <Link
          className={classes.textDecorationNone}
          to={routeConstants.PORTFOLIO}
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<DoubleArrowIcon />}
          >
            CHECK OUT MY PORTFOLIO
          </Button>
        </Link>
      </Fade>
    </Box>
  </Box>
);

ConstructionPart.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ConstructionPart);
