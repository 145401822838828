import React from 'react';
import PropTypes from 'prop-types';
import CenteredLayout from 'Layouts/CenteredLayout/CenteredLayout';
import FullPageError from 'Components/Errors/FullPageError/FullPageError.component';
import errorPagesData from 'STATIC_DATA/errorPagesData';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <CenteredLayout>
          <FullPageError {...errorPagesData.errorBoundary} />
        </CenteredLayout>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
