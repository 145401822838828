import React from 'react';
import CenteredLayout from 'Layouts/CenteredLayout/CenteredLayout';
import ConstructionPart from 'Parts/Blogs/Construction.part';

const BlogsPage = () => (
  <CenteredLayout>
    <ConstructionPart />
  </CenteredLayout>
);

export default BlogsPage;
