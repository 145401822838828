import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Fade, SvgIcon, Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useElementOnScreen } from 'Hooks';

const TechSection = ({ title: sectionTitle, technologies }) => {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '250px',
    threshold: 0,
  });

  return (
    <Box mb={4} key={sectionTitle} ref={containerRef}>
      <Fade in={isVisible} timeout={{ enter: 2000, exit: 500 }}>
        <Box
          borderLeft={1}
          px={2}
          py={1}
          mb={2}
          fontSize="h6.fontSize"
          borderColor="primary.main"
          color="text.secondary"
          fontWeight="fontWeightBold"
        >
          {sectionTitle}
        </Box>
      </Fade>
      <Fade in={isVisible} timeout={{ enter: 3000, exit: 0 }}>
        <Box display="flex" flexWrap="wrap" px={2} mb={2}>
          {
              technologies.map(({ title, isFontAwesomeIcon, icon }) => (
                <Box mr={2} mb={3} key={title}>
                  <Box component="span" color="primary.main">
                    { isFontAwesomeIcon && <FontAwesomeIcon icon={icon} size="3x" />}
                    { !isFontAwesomeIcon && (
                      <Box lineHeight={0.5} fontSize="3rem">
                        <SvgIcon fontSize="inherit">
                          {icon}
                        </SvgIcon>
                      </Box>
                    )}
                  </Box>
                  <Box mt={1}>
                    <Typography color="textSecondary">
                      {title}
                    </Typography>
                  </Box>
                </Box>
              ))
            }
        </Box>
      </Fade>
    </Box>
  );
};

TechSection.propTypes = {
  title: PropTypes.string.isRequired,
  technologies: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
    isFontAwesomeIcon: PropTypes.bool,
  })).isRequired,
};

export default TechSection;
