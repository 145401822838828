import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Box, Fade } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { routeConstants } from 'Constants';
import ProjectViewer from 'Containers/ProjectViewer/ProjectViewer';
import projects from 'STATIC_DATA/projectsData';
import styles from './styles';

const ProjectContainer = ({ classes }) => {
  const { projectId } = useParams();
  const history = useHistory();
  const project = projects[projectId];

  return (
    <div className={classes.container}>
      <Fade in timeout={1000}>
        <Box paddingY={1} className={classes.backButton}>
          <IconButton
            color="primary"
            aria-label="Back to Portfolio"
            onClick={() => history.push(routeConstants.PORTFOLIO)}
          >
            <ArrowBackIosOutlinedIcon />
          </IconButton>
        </Box>
      </Fade>
      <Box
        className={classes.mainContent}
        display="flex"
        justify="center"
        alignItems="center"
      >
        <Box className={classes.projectViewerWrapper}>
          <ProjectViewer project={project} />
        </Box>
      </Box>
    </div>
  );
};

ProjectContainer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ProjectContainer);
