import { createMuiTheme } from '@material-ui/core/styles';
import { amber } from '@material-ui/core/colors';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: amber[500],
    },
    text: {
      primary: amber[500],
      secondary: '#fff',
    },
  },
});

export default darkTheme;
