import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './styles';

const IconLink = ({
  tooltipPlacement, tooltipTitle, to, children, classes,
  disabled, tooltipOpen, externalLink,
}) => (
  <Tooltip
    classes={{
      tooltip: `
        ${classes.tooltip} 
        ${disabled ? classes.darkTooltip : classes.lightTooltip}
      `,
    }}
    placement={tooltipPlacement}
    title={tooltipTitle}
    open={tooltipOpen}
  >
    {externalLink ? (
      <a
        href={to}
        className={`
          ${classes.link} 
          ${!disabled && classes.activeLink}
        `}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ) : (
      <Link
        to={to}
        className={`
          ${classes.link} 
          ${!disabled && classes.activeLink}
        `}
      >
        {children}
      </Link>
    )}
  </Tooltip>
);

IconLink.propTypes = {
  tooltipPlacement: PropTypes.string,
  tooltipTitle: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  tooltipOpen: PropTypes.bool,
  externalLink: PropTypes.bool,
};

IconLink.defaultProps = {
  tooltipPlacement: 'right',
  tooltipTitle: '',
  disabled: false,
  tooltipOpen: undefined,
  externalLink: false,
};

export default withStyles(styles)(IconLink);
