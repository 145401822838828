import * as yup from 'yup';
import { formConstants } from 'Constants';

export default () => ({
  firstName: yup.string().required("It's a required field"),
  lastName: yup.string().required("It's a required field"),
  email: yup.string().required("It's a required field").email('Email entered is invalid'),
  message: yup.string()
    .required("It's a required field")
    .max(formConstants.contactForm.MESSAGE_MAX_LENGTH - 1, 'I am using free storage so I need to restrict you at 500 characters')
    .min(formConstants.contactForm.MESSAGE_MIN_LENGTH, "Single character message is difficult to understand. At least type 'Hi'"),
});
