const styles = (theme) => {
  const rem = theme.typography.pxToRem;

  return ({
    sidebar: {
      display: 'flex',
      height: '100vh',
      borderRight: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.grey[900],
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${rem(16)} 0`,
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: theme.zIndex.drawer,
    },
    verticalDottedBorder: {
      borderLeft: `2px dotted ${theme.palette.primary.main}`,
      height: '10vh',
    },
    logo: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: rem(32),
      textDecoration: 'none',
      '&:hover, &:active': {
        color: theme.palette.primary.dark,
        textDecoration: 'none',
      },
    },
    iconLinksList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
      padding: `${rem(8)} ${rem(24)}`,
    },
  });
};

export default styles;
