import React from 'react';
import {
  Home as HomeIcon,
  Person as PersonIcon,
  MailOutline as MailOutlineIcon,
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faBlog } from '@fortawesome/free-solid-svg-icons';
import { faHackerrank } from '@fortawesome/free-brands-svg-icons';

import { routeConstants } from 'Constants';

export default {
  links: [
    {
      title: 'Home',
      to: routeConstants.HOME,
      icon: () => <HomeIcon />,
    },
    {
      title: 'About',
      to: routeConstants.ABOUT,
      icon: () => <PersonIcon />,
    },
    {
      title: 'Protfolio',
      to: routeConstants.PORTFOLIO,
      icon: () => <FontAwesomeIcon icon={faCode} />,
    },
    {
      title: 'Blogs',
      to: routeConstants.BLOGS,
      icon: () => <FontAwesomeIcon icon={faBlog} />,
    },
    {
      title: 'Contact',
      to: routeConstants.CONTACT,
      icon: () => <MailOutlineIcon />,
    },
  ],
  socialLinks: [
    {
      to: 'https://github.com/sharvan-sharma',
      icon: () => <GitHubIcon />,
    },
    {
      to: 'https://www.linkedin.com/in/sharvan-sharma',
      icon: () => <LinkedInIcon />,
    },
    {
      to: 'https://www.hackerrank.com/sharvan_sharma',
      icon: () => <FontAwesomeIcon icon={faHackerrank} size="lg" />,
    },
  ],
};
