import React from 'react';
import SidebarLayout from 'Layouts/SidebarLayout/SidebarLayout';
import ContactContainer from 'Containers/ContactContainer/ContactContainer';

const ContactPage = () => (
  <SidebarLayout>
    <ContactContainer />
  </SidebarLayout>
);

export default ContactPage;
