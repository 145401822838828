import { request } from 'Utils';
import { apiConstants } from 'Constants';

const { inquiryScuttle, methods, apiStatus } = apiConstants;
const { REACT_APP_CONTACT_FORM_ID, REACT_APP_CONTACT_FORM_TYPE } = process.env;

const createInquiry = (data) => request({
  method: methods.POST,
  baseURL: inquiryScuttle.BASE_API_URL,
  url: inquiryScuttle.CREATE_INQUIRY,
  params: {
    form_type: REACT_APP_CONTACT_FORM_TYPE,
  },
  data: {
    form_client_id: REACT_APP_CONTACT_FORM_ID,
    inquiry: {
      name: {
        firstname: data.firstName.trim(),
        middlename: '',
        lastname: data.lastName.trim(),
      },
      email: data.email.trim(),
      message: data.message.trim(),
    },
  },
}).then((response) => {
  if (response.data && response.data.status === apiStatus.SUCCESS) {
    return {
      success: true,
      data: response.data,
    };
  }
  return {
    success: false,
    data: {},
  };
})
  .catch(() => ({
    success: false,
    data: {},
  }));

export default {
  createInquiry,
};
