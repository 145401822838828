import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box, Typography, Button, Fade,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  DoubleArrow as DoubleArrowIcon,
  Description as DescriptionIcon,
} from '@material-ui/icons/';
import { routeConstants, commonConstants } from 'Constants';
import { useElementOnScreen } from 'Hooks';
import styles from './styles';

const Introduction = ({ classes }) => {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '30px',
    threshold: 1,
  });

  return (
    <Box
      p="1rem"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      className={classes.container}
    >
      <Box my="1rem" ref={containerRef}>
        <Fade in={isVisible} timeout={{ enter: 1500, exit: 500 }}>
          <Box
            mb={1}
            fontWeight="fontWeightBold"
            color="primary.main"
            fontSize="h3.fontSize"
            lineHeight={1}
          >
            Sharvan Sharma
          </Box>
        </Fade>
        <Fade in={isVisible} timeout={{ enter: 2000, exit: 1000 }}>
          <Box mb={2}>
            <Typography variant="h5" color="textSecondary">
              Software Engineer
            </Typography>
          </Box>
        </Fade>
        <Fade in={isVisible} timeout={{ enter: 3000, exit: 2000 }}>
          <Box color="text.disabled" mb={2}>
            I&apos;m a Software Engineer with an interest in architecting innovative solutions to
            solve problems. I have professional experience in web front-end development and
            hands-on experience in back-end development. I&apos;m seeking opportunities in
            the field of
            {' '}
            <Box component="span" color="primary.main">Problem-solving</Box>
            {' '}
            and
            {' '}
            <Box component="span" color="primary.main">Planning</Box>
            .
          </Box>
        </Fade>
        <Fade in={isVisible} timeout={{ enter: 3500, exit: 2500 }}>
          <Box display="flex">
            <Box mr={2}>
              <Link
                to={routeConstants.PORTFOLIO}
                className={classes.textDecorationNone}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<DoubleArrowIcon />}
                  classes={{ root: classes.button }}
                >
                  PORTFOLIO
                </Button>
              </Link>
            </Box>
            <a
              href={commonConstants.RESUME_LINK}
              className={classes.textDecorationNone}
            >
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<DescriptionIcon />}
                >
                  RESUME
                </Button>
              </Box>
            </a>
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

Introduction.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Introduction);
