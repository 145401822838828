import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Fade, Typography, Box, Grid, Button,
} from '@material-ui/core';
import {
  DoubleArrow as DoubleArrowIcon,
  MailOutline as MailOutlineIcon,
} from '@material-ui/icons/';
import { routeConstants } from 'Constants';
import styles from './styles';

const IntroductionPart = ({ classes }) => {
  const [state, setstate] = useState([0, 0, 100]);

  const arr = ['Sharvan Sharma', 'A Software Engineer'];

  const generateText = () => {
    let t = '';
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    for (let i = 0; i <= state[1]; i++) {
      t += arr[state[0]][i];
    }
    return t;
  };

  const ctas = [
    {
      to: routeConstants.PORTFOLIO,
      text: 'CHECK OUT MY PORTFOLIO',
      startIcon: <DoubleArrowIcon />,
    },
    {
      to: routeConstants.CONTACT,
      text: 'CONTACT ME',
      startIcon: <MailOutlineIcon />,
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (state[1] === (arr[state[0]].length - 1)) {
        if (state[0] === (arr.length - 1)) {
          setstate([0, 0, 100]);
        } else {
          setstate([state[0] + 1, 0, 100]);
        }
      } else if (state[1] === (arr[state[0]].length - 2)) {
        setstate([state[0], state[1] + 1, 3000]);
      } else {
        setstate([state[0], state[1] + 1, 100]);
      }
    }, state[2]);

    return () => clearTimeout(timeout);
  }, [state[0], state[1]]);

  return (
    <div className={classes.aligner}>
      <Fade in timeout={{ appear: 3000, exit: 1000, enter: 1000 }}>
        <Box component="h6" className={classes.heading}>
          Hello!  I&apos;m
        </Box>
      </Fade>
      <Fade in timeout={{ appear: 3000, exit: 2000, enter: 2000 }}>
        <Box
          color="primary.main"
          fontWeight="fontWeightBold"
          fontSize="h5.fontSize"
          component="h1"
          mb={1}
        >
          {generateText(state)}
        </Box>
      </Fade>
      <Fade in timeout={{ appear: 5000, exit: 2000, enter: 3000 }}>
        <Box mb={2}>
          <Typography variant="body1" className={classes.description}>
            Based in New Delhi, India.
            I develop
            <span className={classes.highlightedText}> Web Services</span>
            {' '}
            and
            <span className={classes.highlightedText}> Web Applications</span>
            .
          </Typography>
        </Box>
      </Fade>
      <Fade in timeout={{ appear: 7000, exit: 3000, enter: 4000 }}>
        <Grid>
          {
            ctas.map(({ startIcon, to, text }) => (
              <Link key={text} className={classes.textDecorationNone} to={to}>
                <Button
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.button }}
                  startIcon={startIcon}
                >
                  {text}
                </Button>
              </Link>
            ))
          }
        </Grid>
      </Fade>
      <div className={classes.spacer} />
    </div>
  );
};

IntroductionPart.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(IntroductionPart);
