import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Fade,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const FullPageError = ({
  title, headings, paragraphs, classes, buttons,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    flexWrap="wrap"
    alignItems="center"
    className={classes.container}
    color="primary.main"
  >
    <Box display="flex" className={classes.content}>
      {title && (
        <Fade in timeout={1000}>
          <Box pr={2}>
            <Box
              fontWeight="fontWeightBold"
              className={classes.title}
            >
              {title}
            </Box>
          </Box>
        </Fade>
      )}
      {
        (paragraphs.length || headings.length || buttons.length) && (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            {
              headings.map((heading) => (
                <Fade key={heading} in timeout={1500}>
                  <Box
                    component="h6"
                    fontWeight="fontWeightBold"
                    fontSize="h6.fontSize"
                  >
                    {heading}
                  </Box>
                </Fade>
              ))
            }
            {
              paragraphs.map((paragraph) => (
                <Fade in key={paragraphs} timeout={2000}>
                  <Box component="p" color="text.secondary" key={paragraph}>
                    {paragraph}
                  </Box>
                </Fade>
              ))
            }
            {
              buttons.map(({
                text, action, variant, startIcon, color,
              }) => (
                <Fade in key={text} timeout={2500}>
                  <Box>
                    <Button
                      variant={variant}
                      onClick={action}
                      startIcon={startIcon}
                      color={color}
                    >
                      {text}
                    </Button>
                  </Box>
                </Fade>
              ))
            }
          </Box>
        )
      }
    </Box>
  </Box>
);

FullPageError.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  headings: PropTypes.arrayOf(PropTypes.string),
  paragraphs: PropTypes.arrayOf(PropTypes.string),
  buttons: PropTypes.arrayOf(PropTypes.object),
};

FullPageError.defaultProps = {
  title: '',
  headings: [],
  paragraphs: [],
  buttons: [],
};

export default withStyles(styles)(FullPageError);
