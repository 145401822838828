import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, Typography, Button,
  CircularProgress, Snackbar, Slide,
  Fade, IconButton,
} from '@material-ui/core';
import {
  Send as SendIcon,
  MailOutline as MailOutlineIcon,
  Call as CallIcon,
} from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { contactFormService } from 'Services';
import { commonConstants } from 'Constants';
import ContactForm from 'Components/Forms/ContactForm';
import defaultValues from 'Containers/ContactContainer/defaultValues';
import validationSchema from 'Containers/ContactContainer/validationSchema';
import styles from './styles';

const messages = {
  SUCCESS: 'Message sent successfully',
  ERROR: 'Failed to send message',
};

const ContactContainer = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const [snackBar, setSnackBar] = useState({
    severity: null,
    message: null,
    color: null,
    action: () => null,
    variant: null,
  });

  const handleClose = () => setOpen(false);

  const submitHandler = (values, { setSubmitting, resetForm }) => {
    contactFormService.createInquiry(values)
      .then(({ success }) => {
        if (success) {
          setSnackBar({
            severity: 'success',
            message: messages.SUCCESS,
            variant: 'filled',
            action: () => null,
          });
          resetForm(defaultValues());
        } else {
          setSnackBar({
            severity: 'error',
            message: messages.ERROR,
            variant: 'outlined',
            action: (handleClick) => (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  handleClose();
                  handleClick();
                }}
              >
                RETRY
              </Button>
            ),
          });
        }
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (snackBar.severity && snackBar.message) setOpen(true);
  }, [snackBar]);

  return (
    <Grid container classes={{ root: classes.container }}>
      <Grid item xs={12}>
        <Box px={2} my={4}>
          <Box mb={2}>
            <Fade in timeout={1000}>
              <Box
                mb={2}
                fontWeight="fontWeightBold"
                color="primary.main"
                fontSize="h3.fontSize"
                lineHeight={1}
              >
                Let&apos;s Connect
              </Box>
            </Fade>
            <Fade in timeout={2000}>
              <Typography variant="h6" color="textSecondary">
                Have cool idea for new project? Need reliable partner to improve your product?
              </Typography>
            </Fade>
            <Fade in timeout={3000}>
              <Typography variant="h6" color="textSecondary">
                I am here to help you uncomplicate your product development.
              </Typography>
            </Fade>
          </Box>
          <Fade in timeout={4000}>
            <Box>
              <Formik
                initialValues={defaultValues()}
                validationSchema={validationSchema()}
                onSubmit={submitHandler}
              >
                {({
                  handleSubmit, isValid, dirty, isSubmitting,
                }) => (
                  <>
                    <ContactForm />
                    <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <a href={`mailto:${commonConstants.contactInformation.EMAIL}`}>
                          <IconButton color="primary">
                            <MailOutlineIcon />
                          </IconButton>
                        </a>
                      </Box>
                      {isSubmitting
                        ? <CircularProgress size={30} />
                        : (
                          <Box
                            component="span"
                            className={(!dirty || !isValid) ? classes.cursorNotAllowed : ''}
                          >
                            <Button
                              classes={{ root: classes.cursorNotAllowed }}
                              variant="outlined"
                              color="primary"
                              disabled={!dirty || !isValid}
                              onClick={handleSubmit}
                              endIcon={<SendIcon />}
                            >
                              SEND
                            </Button>
                          </Box>
                        )}
                    </Box>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                      TransitionComponent={(props) => <Slide {...props} direction="up" />}
                    >
                      <MuiAlert
                        action={snackBar.action(handleSubmit)}
                        onClose={handleClose}
                        severity={snackBar.severity}
                        variant={snackBar.variant}
                      >
                        {snackBar.message}
                      </MuiAlert>
                    </Snackbar>
                  </>
                )}
              </Formik>
            </Box>
          </Fade>
        </Box>
      </Grid>
    </Grid>
  );
};

ContactContainer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ContactContainer);
