import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
  Language as LanguageIcon,
  GitHub as GitHubIcon,
} from '@material-ui/icons';
import {
  Box, Grid, Typography, Chip, IconButton, Fade,
} from '@material-ui/core';
import ResponsiveImage from 'Components/ResponsiveImage/ResponsiveImage.component';
import styles from './styles';

const ProjectViewer = ({ project, classes }) => {
  const theme = useTheme();
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);

  const getBreakpointString = (breakpoint) => breakpoint.split(' ')[1];

  return (
    <Grid container>
      <Grid item md={8} xs={12} classes={{ root: classes.section }}>
        <Fade in timeout={1000}>
          <Box
            bgcolor="primary"
            className={classes.mainArea}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <a
              className={classes.banner}
              href={project.images.lg[selectedThumbnail].link}
            >
              <ResponsiveImage
                responsive={[
                  {
                    id: 'small-screen',
                    breakpoint: getBreakpointString(theme.breakpoints.down('xs')),
                    src: project.images.sm[selectedThumbnail].src,
                  },
                  {
                    id: 'large-screen',
                    breakpoint: getBreakpointString(theme.breakpoints.up('sm')),
                    src: project.images.lg[selectedThumbnail].src,
                  },
                ]}
                defaultOption={{
                  src: project.images.lg[selectedThumbnail].src,
                  alt: project.images.lg[selectedThumbnail].alt,
                }}
              />
            </a>
          </Box>
        </Fade>
        <Fade in timeout={1500}>
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            pt={2}
          >
            {
            project.images.lg.map((image, index) => (
              <button
                type="button"
                key={image.src + image.alt}
                className={classes.thumbnail}
                onClick={() => setSelectedThumbnail(index)}
              >
                {selectedThumbnail !== index && <div className={classes.overlay} />}
                <ResponsiveImage
                  responsive={[
                    {
                      id: 'small-screen',
                      breakpoint: getBreakpointString(theme.breakpoints.down('xs')),
                      src: project.images.sm[index].src,
                    },
                    {
                      id: 'large-screen',
                      breakpoint: getBreakpointString(theme.breakpoints.up('sm')),
                      src: image.src,
                    },
                  ]}
                  defaultOption={{
                    src: image.src,
                    alt: image.alt,
                  }}
                />
              </button>
            ))
        }
          </Box>
        </Fade>
      </Grid>
      <Grid item md={4} xs={12} px={0} classes={{ root: `${classes.section} ${classes.contentSection}` }}>
        <Fade in timeout={1000}>
          <Box
            p={2}
            className={classes.introduction}
          >
            <Typography variant="h4" color="textSecondary">{project.name}</Typography>
            <Box mt={2}>
              {
            project.tags.map((tag) => (
              <Chip
                key={tag}
                variant="outlined"
                color="primary"
                size="small"
                label={tag}
                classes={{ root: classes.chip }}
              />
            ))
          }
            </Box>
          </Box>
        </Fade>
        <Fade in timeout={1500}>
          <Box
            className={classes.descriptionWrapper}
            borderColor="grey.800"
            borderTop={1}
          >
            <Typography
              classes={{ root: classes.description }}
              paragraph
              color="textSecondary"
            >
              {project.description}
            </Typography>
          </Box>
        </Fade>
        <Fade in timeout={2000}>
          <Box
            className={classes.links}
            p={2}
            borderColor="grey.800"
            borderTop={1}
          >
            <IconButton
              href={project.repositories[0].link}
              target="_blank"
              rel="noopener noreferrer"
              classes={{ colorPrimary: classes.link }}
              color="primary"
            >
              <GitHubIcon />
            </IconButton>
            <IconButton
              href={project.website}
              target="_blank"
              rel="noopener noreferrer"
              classes={{ colorPrimary: classes.link }}
              color="primary"
            >
              <LanguageIcon />
            </IconButton>
          </Box>
        </Fade>
      </Grid>
    </Grid>
  );
};

ProjectViewer.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ProjectViewer);
