export default (theme) => {
  const rem = theme.typography.pxToRem;
  return {
    container: {
      position: 'relative',
    },
    mainContent: {
      minHeight: 'calc(100vh - 64px)',
    },
    projectViewerWrapper: {
      // height of back button is 64px
      margin: `${rem(64)} 0 ${rem(32)}`,
    },
    backButton: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  };
};
