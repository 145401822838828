import React from 'react';
import SidebarLayout from 'Layouts/SidebarLayout/SidebarLayout';
import { Introduction, TechStack } from 'Parts/About';

const AboutPage = () => (
  <SidebarLayout allowScrollSnap>
    <Introduction />
    <TechStack />
  </SidebarLayout>
);

export default AboutPage;
