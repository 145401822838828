import React from 'react';
import PropTypes from 'prop-types';
import { Box, Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useElementOnScreen } from 'Hooks';
import techStackData from 'STATIC_DATA/techStackData';
import TechSection from './TechSection.part';
import styles from './styles';

const { sections } = techStackData;

const TechStack = ({ classes }) => {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '30px',
    threshold: 0,
  });

  return (
    <Box
      p="1rem"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      className={classes.container}
      ref={containerRef}
    >
      <Fade in={isVisible}>
        <Box my="3rem">
          <Fade in={isVisible} timeout={{ enter: 1000, exit: 3000 }}>
            <Box
              mb={3}
              fontSize="h4.fontSize"
              fontWeight="fontWeightBold"
              color="primary.main"
            >
              My Tech Stack
            </Box>
          </Fade>
          <Fade in={isVisible} timeout={{ enter: 2000, exit: 2000 }}>
            <Box>
              { sections.map((section) => <TechSection key={section.title} {...section} />)}
            </Box>
          </Fade>
        </Box>
      </Fade>
    </Box>
  );
};

TechStack.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(TechStack);
