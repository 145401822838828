import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from '@material-ui/core';

const InputField = (props) => {
  const {
    name,
    helperText,
  } = props;
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <TextField
          {...props}
          {...field}
          error={Boolean(meta.touched && meta.error)}
          helperText={(meta.touched && meta.error) || helperText}
        />
      )}
    </Field>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

InputField.defaultProps = {
  helperText: '',
};

export default InputField;
