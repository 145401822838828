import {
  faJsSquare,
  faNode,
  faHtml5,
  faCss3Alt,
  faReact,
  faNpm,
  faPython,
  faAws,
} from '@fortawesome/free-brands-svg-icons';
import { icons } from 'Constants';

export default {
  sections: [
    {
      title: 'Frontend',
      technologies: [
        {
          title: 'HTML 5',
          isFontAwesomeIcon: true,
          icon: faHtml5,
        },
        {
          title: 'CSS 3',
          isFontAwesomeIcon: true,
          icon: faCss3Alt,
        },
        {
          title: 'JavaScript',
          isFontAwesomeIcon: true,
          icon: faJsSquare,
        },
        {
          title: 'React',
          isFontAwesomeIcon: true,
          icon: faReact,
        },
        {
          title: 'Redux',
          icon: icons.redux,
        },
        {
          title: 'Material-UI',
          icon: icons.materialUI,
        },
      ],
    },
    {
      title: 'Backend',
      technologies: [
        {
          title: 'Node.js',
          isFontAwesomeIcon: true,
          icon: faNode,
        },
        {
          title: 'Express.js',
          icon: icons.Expressjs,
        },
      ],
    },
    {
      title: 'Programing Languages',
      technologies: [
        {
          title: 'JavaScript',
          isFontAwesomeIcon: true,
          icon: faJsSquare,
        },
        {
          title: 'Python',
          isFontAwesomeIcon: true,
          icon: faPython,
        },
        {
          title: 'C++',
          icon: icons.cPlusPlus,
        },
        {
          title: 'Bash',
          icon: icons.bash,
        },
      ],
    },
    {
      title: 'Databases',
      technologies: [
        {
          title: 'MongoDB',
          icon: icons.mongoDB,
        },
        {
          title: 'MySQL',
          icon: icons.mySql,
        },
      ],
    },
    {
      title: 'Cloud',
      technologies: [
        {
          title: 'AWS S3',
          isFontAwesomeIcon: true,
          icon: faAws,
        },
        {
          title: 'Firebase',
          icon: icons.firebase,
        },
        {
          title: 'Heroku',
          icon: icons.heroku,
        },
        {
          title: 'Netlify',
          icon: icons.netlify,
        },
        {
          title: 'Vercel',
          icon: icons.vercel,
        },
        {
          title: 'npm',
          isFontAwesomeIcon: true,
          icon: faNpm,
        },
        {
          title: 'MongoDB Atlas',
          icon: icons.mongoDB,
        },
      ],
    },
  ],
};
