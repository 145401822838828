import React, { useEffect, useState } from 'react';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
  IconButton, Fade, Box, Typography,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { routeConstants } from 'Constants';
import PropTypes from 'prop-types';
import ResponsiveImage from 'Components/ResponsiveImage/ResponsiveImage.component';
import projects from 'STATIC_DATA/projectsData';
import styles from './styles';

function Portfolio({ classes }) {
  const theme = useTheme();
  const [slideIndex, setSlideIndex] = useState(0);

  const getBreakpointString = (breakpoint) => breakpoint.split(' ')[1];

  let currentProject = projects[slideIndex];
  useEffect(() => {
    currentProject = projects[slideIndex];
  }, [slideIndex]);

  const handleChangeIndex = (index) => {
    setSlideIndex(index);
  };

  return (
    <div className={classes.page}>
      <Fade in timeout={{ enter: 1000, exit: 300, appear: 500 }}>
        <Box mb={2}>
          <Typography variant="h5" color="primary" align="center">
            Check Out My Projects
          </Typography>
        </Box>
      </Fade>
      <Fade in timeout={{ appear: 1000, enter: 3000, exit: 300 }}>
        <Box className={classes.bannerContainer}>
          <SwipeableViews
            index={slideIndex}
            onChangeIndex={handleChangeIndex}
            enableMouseEvents
          >
            {
              projects.map(({ id, images }) => (
                <Link key={id} to={(routeConstants.PROJECT).replace(':projectId', id)}>
                  <ResponsiveImage
                    responsive={[
                      {
                        id: 'small-screen',
                        breakpoint: getBreakpointString(theme.breakpoints.down('xs')),
                        src: images.sm[0].src,
                      },
                      {
                        id: 'large-screen',
                        breakpoint: getBreakpointString(theme.breakpoints.up('sm')),
                        src: images.lg[0].src,
                      },
                    ]}
                    defaultOption={{
                      src: images.lg[0].src,
                      alt: images.lg[0].alt,
                      cssClasses: [classes.bannerImage],
                    }}
                  />
                </Link>
              ))
            }
          </SwipeableViews>
        </Box>
      </Fade>
      <Fade in timeout={{ appear: 100, enter: 5000, exit: 300 }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.navigateSection}
        >
          <IconButton
            color="primary"
            disabled={slideIndex === 0}
            className={classes.root}
            onClick={() => setSlideIndex((index) => index - 1)}
          >
            <NavigateBefore />
          </IconButton>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            p={2}
            className="col-10 col-md-6 col-lg-4"
          >
            <Link
              to={(routeConstants.PROJECT).replace(':projectId', slideIndex)}
              className={classes.textDecorationNone}
            >
              <Typography
                variant="h6"
                color="primary"
                align="center"
              >
                {currentProject.name}
              </Typography>
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
            >
              {currentProject.shortDescription}
            </Typography>
          </Box>
          <IconButton
            color="primary"
            disabled={slideIndex === (projects.length - 1)}
            onClick={() => setSlideIndex((index) => index + 1)}
            className={classes.root}
          >
            <NavigateNext />
          </IconButton>
        </Box>
      </Fade>
    </div>
  );
}

Portfolio.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Portfolio);
