export default (theme) => {
  const rem = theme.typography.pxToRem;

  return {
    tooltip: {
      maxWidth: 220,
      fontSize: rem(12),
      backgroundColor: theme.palette.primary.contrastText,
    },
    lightTooltip: {
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    darkTooltip: {
      color: theme.palette.grey[500],
      border: `2px solid ${theme.palette.grey[500]}`,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.grey[500],
      '&:hover, &:active': {
        color: theme.palette.grey[700],
      },
    },
    activeLink: {
      color: theme.palette.primary.main,
      '&:hover, &:active': {
        color: theme.palette.primary.dark,
      },
    },
  };
};
