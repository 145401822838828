import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Box, Slide } from '@material-ui/core';

import { routeConstants } from 'Constants';
import sidebarData from 'STATIC_DATA/sidebarLinksData';
import IconLink from 'Components/IconLink/IconLink.component';
import styles from './styles';

const { links, socialLinks } = sidebarData;

const Sidebar = ({ classes, open, withAnimation }) => {
  const { pathname } = useLocation();

  const SidebarContent = () => (
    <>
      <Link to={routeConstants.HOME} className={classes.logo}>S</Link>
      <div className={classes.verticalDottedBorder} />
      <ul className={classes.iconLinksList}>
        {
        links.map((link) => (
          <li className={classes.link} key={link.to}>
            <IconLink
              disabled={pathname !== link.to}
              tooltipTitle={link.title}
              tooltipPlacement="right"
              to={link.to}
            >
              {link.icon()}
            </IconLink>
          </li>
        ))
      }
      </ul>
      <div className={classes.verticalDottedBorder} />
      <ul className={classes.iconLinksList}>
        {
        socialLinks.map((link) => (
          <li className={classes.link} key={link.to}>
            <IconLink
              tooltipTitle={link.title}
              tooltipPlacement="right"
              to={link.to}
              externalLink
            >
              {link.icon()}
            </IconLink>
          </li>
        ))
      }
      </ul>
    </>
  );

  if (withAnimation) {
    // TODO Create with animation HOC
    return (
      <Slide
        unmountOnExit
        mountOnEnter
        direction="right"
        in={open}
        timeout={{ enter: 500, exit: 500 }}
      >
        <Box component="div" className={classes.sidebar}>
          <SidebarContent />
        </Box>
      </Slide>
    );
  }

  return (
    <Box component="div" className={classes.sidebar}>
      <SidebarContent />
    </Box>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.bool,
  withAnimation: PropTypes.bool,
};

Sidebar.defaultProps = {
  open: true,
  withAnimation: false,
};

export default withStyles(styles)(Sidebar);
