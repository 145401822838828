import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Backdrop, IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Sidebar from 'Containers/Sidebar/Sidebar';
import styles from './styles';

const SidebarLayout = ({ children, classes, allowScrollSnap }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleToggle = () => setOpen((sidebarOpenState) => !sidebarOpenState);

  return (
    <div className={allowScrollSnap ? classes.scrollSnapContainer : ''}>
      <div className={classes.mobileOnly}>
        <Backdrop
          className={classes.backdrop}
          open={open}
          onClick={handleClose}
        />
        <Sidebar open={open} withAnimation />
      </div>
      <div className={classes.desktopOnly}>
        <Sidebar />
      </div>
      <div
        className={classes.mainContent}
      >
        <Container maxWidth="md" classes={{ root: classes.container }}>
          <IconButton onClick={handleToggle} classes={{ root: classes.toggleButton }}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </IconButton>
          {children}
        </Container>
      </div>
    </div>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  allowScrollSnap: PropTypes.bool,
};

SidebarLayout.defaultProps = {
  allowScrollSnap: false,
};

export default withStyles(styles)(SidebarLayout);
