import React from 'react';

import SidebarLayout from 'Layouts/SidebarLayout/SidebarLayout';
import IntroductionPart from 'Parts/Introduction/Introduction.part';

const LandingPage = () => (
  <SidebarLayout>
    <IntroductionPart />
  </SidebarLayout>
);

export default LandingPage;
