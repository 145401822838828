export default (theme) => ({
  page: {
    backgroundColor: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    minHeight: '100vh',
  },
});
