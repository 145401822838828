export default (theme) => {
  const rem = theme.typography.pxToRem;
  return {
    root: {
      outline: 'none !important',
    },
    page: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
      padding: `${rem(64)} ${rem(16)}`,
    },
    bannerContainer: {
      maxWidth: '768px',
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
        padding: `0 ${rem(16)}`,
        maxWidth: '320px',
        textAlign: 'center',
      },
    },
    bannerImage: {
      width: '100%',
      height: '369px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '550px',
      },
    },
    navigateSection: {
      width: '100%',
      padding: `0 ${rem(5)}`,
    },
    textDecorationNone: {
      textDecoration: 'none',
    },
  };
};
